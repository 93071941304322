const popup = (() => {
    'use strict';

    const initPopup = () => {

        let activeClass = 'show-popup',
            btnOpen = $('.btn-open-box'),
            popupBox = $('.grid-popup');

        if (!$(popupBox).length) {
            return;
        }

        btnOpen.on('click keypress', function(e) {
            if (e.which === 13 || e.type === 'click') {
                e.preventDefault();
                togglePopup(this);
            }
        });

        function togglePopup(target) {

            let text = $(target).children().text();

            if ($(target).parent().hasClass('show-popup')) {
                $(target).attr('aria-expanded', 'false');
                $(target).parent().find(popupBox).attr('aria-hidden', 'true');
                $(target).parent().removeClass(activeClass);
            } else {
                $(target).parent().addClass(activeClass);
                $(target).attr('aria-expanded', 'true');
                $(target).parent().find(popupBox).attr('aria-hidden', 'false');
            }
            $(target).children().text(text == "Open" ? "Close" : "Open");
        }
    };

    return {
        init: initPopup
    };

})();

export default popup;