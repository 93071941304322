const entries = require('object.entries');

const polyfill = (() => {
    'use strict';
    const init = () => {
        if (!Object.entries) {
            entries.shim();
        }
    };
    return {
        init: init
    };
})();

export default polyfill;