const matchHeight = (() => {
    'use strict';
    const init = () => {

        let holder = $('.stories-slider .text-holder');

        if (!$(holder).length) {
            return;
        }

        const matchHeightInit = () => {
            holder.matchHeight();
        };

        if ($(window).width() >= 700) {
            matchHeightInit();
        }

    };
    return {
        init: init
    };
})();

export default matchHeight;