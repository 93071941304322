const popupVideo = (() => {
    'use strict';

    const initVideoPopup = () => {

        let activeClassVideo = 'show-video';
        let btnVideo = $('.btn-video-open');
        let closeVideo = $('.btn-video-close');
        let popupVideo = $('.video-iframe');

        if (!$(popupVideo).length) {
            return;
        }

        btnVideo.on('click keypress', function(e) {
            if (e.which === 13 || e.type === 'click') {
                e.preventDefault();
                openVideo(this);
                playerPlay();
            }
        });

        closeVideo.on('click', function(e) {
            if (e.which === 13 || e.type === 'click') {
                e.preventDefault();
                closeVideoInit(this);
            }
        });

        function openVideo(target) {
            if ($(target).parent().hasClass('show-video')) {
                $(target).attr('aria-expanded', 'false');
                $(target).parent().find(popupVideo).attr('aria-hidden', 'true');
                $(target).parent().removeClass(activeClassVideo);
            } else {
                $(target).parent().addClass(activeClassVideo);
                $(target).attr('aria-expanded', 'true');
                $(target).parent().find(popupVideo).attr('aria-hidden', 'false');
            }
        };

        function closeVideoInit(target) {
            $(target).parent().removeClass(activeClassVideo);
            $(target).parent().find(btnVideo).attr('aria-expanded', 'false');
            $(target).parent().find(popupVideo).attr('aria-hidden', 'true');
        };
    };

    return {
        init: initVideoPopup
    };

})();

export default popupVideo;