import '../plugins/datatables.min.js'

const dataTable = (() => {
    'use strict';
    const init = () => {
      // Pattern
      pattern();
      // Directory
      directory();
    };

    function pattern() {
      let $tableContent = $('.content-wrap > table');

      if (!$tableContent.attr('style')) {
        $tableContent.attr('style', 'width: 100%');
      }

      $tableContent.DataTable({
          "bFilter": false,
          "bPaginate": false,
          "info": false,
          "ordering": false,
          "tabIndex": '-1',
          "responsive": false,
          "columnDefs": [ {
              "targets": '_all',
          }],
      });
    }

    function directory() {
      let ajaxPath = "../assets/data/table.json";
      let $tableHolder = $('.table-directory #table');

      let $tableContent = $('.table-directory .tablet-content');

      $tableContent.DataTable({
          "bFilter": false,
          "bPaginate": false,
          "info": false,
          "ordering": false,
          "tabIndex": '-1',
          "columnDefs": [ {
              "targets": '_all',
          }],
          responsive: {
              breakpoints: [
                  { name: 'desktop',  width: Infinity },
                  { name: 'tablet-l', width: 1024 },
                  { name: 'tablet-p', width: 768 },
                  { name: 'mobile-l', width: 480 },
                  { name: 'mobile-p', width: 320 }
              ],
              details: {
                  display: $.fn.dataTable.Responsive.display.childRowImmediate,
                  renderer: $.fn.dataTable.Responsive.renderer.tableAll( {
                      tableClass: 'table-mobile'
                  } )
              }
          },

      });

      const table = $tableHolder.DataTable({
          "ajax": ajaxPath,
          "processing": true,
          "deferLoading": 57,
          "ordering": false,
          "tabIndex": '-1',
          language: {
              search: "Search Within Directory",
              searchPlaceholder: "Keyword",
              info: "_START_-_END_ of _TOTAL_",
              "sLengthMenu": '<select name="table_length">'+
              '<option value="10">Show 10</option>'+
              '<option value="20">Show 20</option>'+
              '<option value="30">Show 30</option>'+
              '<option value="40">Show 40</option>'+
              '<option value="50">Show 50</option>'+
              '<option value="-1">All</option>'+
              '</select>'
          },
          "columns": [
              { "data": "status" },
              { "data": "program" },
              { "data": "email" },
              { "data": "phone" },
              { "data": "office" }
          ],
          "columnDefs": [ {
              "targets": 0,
              "data": "status",
              "render": function ( data, type, row) {
                  if(row.url) {
                      return '<a class="link-arrow" href="' + row.url + '">'+row.name+'' +
                          '<em class="icon icon-right" aria-hidden="true"></em>' +
                          '</a>' + '<span class="type">'+data+'</span>';
                  }
                  return '<span class="name" >'+row.name+'</span>' + '<span class="type ">'+data+'</span>';
              }},
              {
              "targets": 2,
              "data": "email",
              "render": function (data, type, row) {
                  return '<a class="email" href="' + row.email + '">' + data + '</a>';
              }},{
              "targets": 3,
              "data": "phone",
              "render": function (data, type, row) {
                  return '<a class="tel" href="tel:'+row.phone+'">' + data + '</a>';
              }},{
              "targets": '_all',
              "orderable": false
          }],
          dom: '<"heading-table"<"toolbar">rf><"wrapper-table"t><"footer-table"<"control-left"li>p>',
          keys: true,
          responsive: {
              breakpoints: [
                  { name: 'desktop',  width: Infinity },
                  { name: 'tablet-l', width: 1024 },
                  { name: 'tablet-p', width: 700 },
                  { name: 'mobile-l', width: 480 },
                  { name: 'mobile-p', width: 320 }
              ],
              details: {
                  display: $.fn.dataTable.Responsive.display.childRowImmediate,
                  renderer: $.fn.dataTable.Responsive.renderer.tableAll( {
                      tableClass: 'table-mobile'
                  } )
              }
          },

          "initComplete": function () {
              this.api().columns().every( function () {

                  let column = this,
                      toolbar = $(".toolbar"),
                      holder = document.createElement('div'),
                      label = document.createElement('label'),
                      searchHolder = $('#table_filter'),
                      select = $('<select class="custom-select"><option value="">All</option></select>');

                  function _getControlId(string) {
                    return `control-${string.replace(/\s+/g, '').replace(/\//, '-').toLowerCase()}`;
                  }

                  $(label).text(this.header().innerText);

                  $(label).attr('for', _getControlId(this.header().innerText));
                  select.attr('id', _getControlId(this.header().innerText));

                $(label).appendTo(holder);
                  select.appendTo(holder);
                  $(holder).addClass('filter-box').appendTo(toolbar);
                  searchHolder.appendTo(toolbar);

                  select.on( 'change', function () {
                      let val = $.fn.dataTable.util.escapeRegex(
                          $(this).val()
                      );
                      column.search( val ? ''+val+'$' : '', true, false ).draw();
                  } );

                  column.data().unique().sort().each( function ( d, j ) {
                      select.append( '<option value="'+d+'">'+d+'</option>' );
                  } );
              } );
          },

          "fnDrawCallback": function() {
              $('.paginate_button.previous').text("Previous page");
              $('.paginate_button.next').text("Next page");

              $('.paginate_button').on('click keypress', function(ev){

                  if (ev.which === 13 || ev.type === 'click') {

                      ev.preventDefault();

                      const delay = 1000;
                      const $el = $(this);

                      $('tr td:first-child').removeAttr('tabindex');

                      setTimeout(() => {
                          let scrollEl = $el;

                          if ($(scrollEl).length != 0) {
                              $('body,html').animate({ scrollTop: $(scrollEl).offset().top - 30}, delay);
                          }
                      }, $el.data('go-to-delay') || 0);

                  }

              });
          }
      });

      const selectShowInit = () => {
          $('[name="table_length"]').addClass('custom-select');
      };

      const renderBtnClear = () => {
          let btnClear = document.createElement('button');
          let iconClear = document.createElement('em');
          let searchHolder = $('#table_filter');

          $(btnClear).addClass('clear-table').text('Reset Table');
          $(iconClear).addClass('icon icon-refresh').attr('aria-hidden', 'true');
          $(iconClear).appendTo(btnClear);
          $(btnClear).insertAfter(searchHolder);
      };

      const resetFilter = () => {
          $('.clear-table').on('click', function(e){
              e.preventDefault();
              $('.custom-select').prop('selectedIndex',0);
              table.search( '' ).columns().search( '' ).draw();
          });
      };

      const paginText = () => {
          $('.paginate_button.previous').text("Previous page");
          $('.paginate_button.next').text("Next page");
      };

      const cellTabindexRemove = () => {
          $('tr td:first-child').removeAttr('tabindex');
      };

      const moveToFirst = () => {
          $('.paginate_button').on('click keypress', function(ev){


              if (ev.which === 13 || ev.type === 'click') {

                  ev.preventDefault();

                  const delay = 1000;
                  const $el = $(this);

                  setTimeout(() => {
                      let scrollEl = $el;

                      if ($(scrollEl).length != 0) {
                          $('body,html').animate({ scrollTop: $(scrollEl).offset().top - 30}, delay);
                      }
                  }, $el.data('go-to-delay') || 0);

              }

          });
      };

      table.on( 'init.dt', function () {
          renderBtnClear();
          resetFilter();
          selectShowInit();
          paginText();
          cellTabindexRemove();
          moveToFirst();
      });
    }

    return {
        init: init
    };
})();

export default dataTable;