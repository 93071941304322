const stickyHeader = (() => {
    'use strict';
    const init = () => {

        let lastScrollTop = 0;

        function scrollHeader() {

            let holder =  $('#header'),
                headerHeight = holder.height(),
                alertHeight = $('.alert').innerHeight(),
                headerOffset = holder.offset().top,
                classDown = 'scrolling-down',
                classUp = 'scrolling-up',
                st = $(window).scrollTop();

            if ($('.alert').length) {
                headerHeight = headerHeight + headerOffset;
            }

            if (st > lastScrollTop && lastScrollTop >= headerHeight){
                setTimeout(function() {
                    holder.addClass('animate-header');
                }, 0);
                holder.addClass(classDown);
                holder.removeClass(classUp);
            }
            if (($(document).scrollTop() > $(window).height()) && (st > lastScrollTop)) {
                setTimeout(function() {
                    holder.addClass('animate-header');
                }, 0);
                holder.addClass(classDown);
            }
            if (st < lastScrollTop && lastScrollTop > headerHeight){
                holder.addClass(classUp);
                holder.removeClass(classDown);
            }
            lastScrollTop = st;

            if ($('.alert').length) {
                if (st <= alertHeight) {
                    holder.removeClass('animate-header');
                    setTimeout(function() {
                        holder.removeClass('animate-header');
                    }, 0);
                    holder.removeClass(classDown);
                    holder.removeClass(classUp);
                }
            }

            if (st <= (177 - headerHeight)) {
                holder.removeClass('animate-header');
                setTimeout(function() {
                    holder.removeClass('animate-header');
                }, 0);
                holder.removeClass(classDown);
                holder.removeClass(classUp);
            }
        }

        $(window).on('load scroll',function(){

            scrollHeader();
        });
    };
    return {
        init: init
    };
})();

export default stickyHeader;