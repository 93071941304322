import popupVideo from "./popup-video";

const autoPlayVideo = (() => {
    'use strict';
    const init = () => {

        if (!$('.video-iframe').length) {
            return;
        }

        // API player
        function playerPlay() {

            let playerWrapper, currentPlayer, playerType, command;

            playerWrapper = $('.video-iframe');
            currentPlayer = $('.video-iframe').find('iframe').get(0);

            if (playerWrapper.hasClass('vimeo')) {
                command = {
                    "method": "play",
                    "value": "true"
                };
            } else {
                command = {
                    "event": "command",
                    "func": "playVideo"
                };
            }

            //check if the player exists.
            if (currentPlayer != undefined) {
                //post our command to the iframe.
                currentPlayer.contentWindow.postMessage(JSON.stringify(command), "*");
            }
        }

        $('.btn-video-open').on('click keypress', function(e) {
            if (e.which === 13 || e.type === 'click') {
                e.preventDefault();
                playerPlay();
                $(this).parent().find('video-image').css('visibility','hidden');
                $(this).css('display','none');
            }
        });

    };
    return {
        init: init
    };
})();

export default autoPlayVideo;