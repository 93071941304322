import '../plugins/tweetie.min.js';

const twitterLoader = (function ($) {
   'use strict';
    const init = () => {
        // Implements twitter
        if ($('.twitter-posts').length) {
            var tweetun = $('.twitter-posts').data('un');

            $('.twitter-posts').tweetie({
                "type": "timeline",
                "template": '<div class="box"><span class="twitter-title"></span><p class="tweet-text">{{tweet.text}}</p><span class="time">{{tweet.created_at}}</span></div>',
                "dateFormat": "%B %d",
                'url': '/assets/api/server.php',
                "params": {
                    "count": 4,
                    "screen_name": tweetun,
                }
            }); 

            setTimeout(() => {
                $('.tweet-text').html(function () {
                    var urlRegex = /(https?:\/\/t.co\/[^\s]+)/g;
                    return $(this).html().replace(urlRegex, function(url) {
                        return '<a target="_blank" href="' + url + '">' + url + '</a>';
                    })
                });
            }, 500);
        }
    };
    return {
        init: init
    };
}(jQuery));

export default twitterLoader;