const datePicker = (() => {
    const init = () => {
        let calendar = $("#datapicker");

        if (!$(calendar).length) {
            return;
        }

        calendar.datepicker({
            autoSize: true,
            changeMonth: false,
            changeYear: false,
            yearRange: '1950:today',
            dateFormat: "yy-mm-dd",
            beforeShowDay : function(date) {
                var day = date.getDate();
                return [true, (day < 10 ? "zero" : "")];
            }
        });
        
    };
    return {
        init: init
    };
})();

export default datePicker;