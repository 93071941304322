const scrollrEffect = (() => {
    'use strict';

    const initScroll = () => {

        let s = skrollr.init({
            smoothScrolling: false,
            forceHeight: false
        });

    };

    return {
        init: initScroll
    };

})();

export default scrollrEffect;