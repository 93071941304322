const modulBgImg = (() => {
    'use strict';
    const init = () => {

        let item = $(".grid-item").find('img');

        if (!$(item).length) {
            return;
        }

        const initImg2bg = () => {
            item.img2bg({
                cssClass: 'img2bg',
                addParentOrSiblingElement: false,
                addedElement: 'div',
                hideImage: true
            });
        };

        $(window).on('load resize orientationchange', function(){
            initImg2bg();
        });

    };
    return {
        init: init
    };
})();

export default modulBgImg;