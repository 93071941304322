const popupFocus = (() => {
    'use strict';

    const init = () => {

        let link = $('.stories-slide a');
        let holder = '.stories-slide';

        if (!$(holder).length) {
            return;
        }

        link.focus(function () {
            $(this).closest(holder).addClass("hover");

        }).blur(function () {
            $(this).closest(holder).removeClass("hover");
        });
    };

    return {
        init: init
    };

})();

export default popupFocus;