const moveElements = (() => {
    'use strict';
    const init = () => {

        let navButtons = $('.nav-buttons');
        let logo = $('.header-logo');
        let navigation = $('.nav-site');
        let navLinks = $('.nav-links');
        let btnSearch = $('.search-holder');
        let dropInfo = $('.information-drop');
        let is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        const prependElement = (elem, refElem) => {
            elem.insertBefore(refElem);
        };
        const insertAfterElement = (elem, refElem) => {
            elem.insertAfter(refElem);
        };

        const changeHeader = () => {
            let wWidth = window.innerWidth;
            if(is_safari) {
                wWidth = window.innerWidth - 15;
            }
            if (wWidth >= 320 && wWidth < 700){
                insertAfterElement(btnSearch, logo);
                prependElement(navButtons, navigation);

            } else if (wWidth >= 700 && wWidth <= 1024) {
                insertAfterElement(btnSearch, logo);
                insertAfterElement(navButtons, btnSearch);
            } else {
                prependElement(btnSearch, navLinks);
                insertAfterElement(navButtons, dropInfo);
            }
        };

        $(window).on('load resize orientationchange', function(){
            changeHeader();
        });

    };
    return {
        init: init
    };
})();

export default moveElements;