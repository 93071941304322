const openClose = (() => {
    'use strict';

    const init = () => {

        let element = $('.section-button');
        let dropdown = $('.section-drop');

        function openSection() {
            if (element.hasClass('open-drop')) {
                element.attr('aria-expanded', 'false');
                dropdown.slideUp(300);
                dropdown.attr('aria-hidden', 'true');
                element.removeClass('open-drop');
            } else {
                element.addClass('open-drop');
                element.attr('aria-expanded', 'true');
                dropdown.delay(200).slideDown(300);
                dropdown.attr('aria-hidden', 'false');
            }
        }

        element.on('click keypress', function(e){
            if (e.which === 13 || e.type === 'click') {
                e.preventDefault();
                openSection(e);
            }
        });

        $(document).on('touchstart click', function(e) {
            let sectionTarget = $('.section-menu');

            if (!sectionTarget.is(e.target) && sectionTarget.has(e.target).length === 0) {
                if (element.hasClass('open-drop')) {
                    openSection(e);
                }
            }
        });
    };

    return {
        init: init
    };

})();

export default openClose;