const filterCollapse = (() => {
    'use strict';
    const init = () => {

        let btnOpen = '.filter-btn-mob',
            holder = $('.filter-list'),
            btnClose = $('.filter-close'),
            duration = 300;

        function openFilter() {
            holder.collapse({
                clickQuery: btnOpen,
                accordion: true,
                open: function() {
                    this.slideDown(duration);
                },
                close: function() {
                    this.slideUp(duration);
                }
            });
        }

        function filterCloseMobile() {
            btnClose.on('click', function () {
                holder.trigger("close");
            });
        }

        openFilter();
        filterCloseMobile();

        $('.filter-btn-wrap').keypress(function (e) {
            let key = e.which;
            if(key == 13) {
                $(btnOpen).click();
            }
        });

        $('.mobile-filter-drop').removeAttr('aria-hidden');

        $(window).on('load resize orientationchange', function(){
            if($(window).width() >= 1024) {
                $('.mobile-filter-drop').removeAttr('aria-hidden');
            }
        });

    };
    return {
        init: init
    };
})();

export default filterCollapse;