const openSearch = (() => {
    'use strict';
    const init = () => {

        let btnSearch = $('.btn-search'),
            holder = $("#header"),
            searchHolder = $('.search-panel'),
            searchField =  $('.search-field');

        if (!$(searchHolder).length) {
            return;
        }

        btnSearch.on('click keypress', function(e) {
            if (e.which === 13 || e.type === 'click') {
                e.preventDefault();
                openSearch(this);
            }
        });

        function openSearch(target) {
            if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
                holder.trigger('close');
            }

            if($(target).hasClass('is-active')) {
                searchHolder.attr('aria-hidden', 'true');
                $(target).attr('aria-expanded', 'false');
                searchHolder.slideToggle('slow');
                $(target).removeClass('is-active');
            } else {
                $(target).addClass('is-active');
                searchHolder.attr('aria-hidden', 'false');
                $(target).attr('aria-expanded', 'true');
                searchHolder.slideToggle('slow');
                searchField.focus();
            }
        }
    };
    return {
        init: init
    };
})();

export default openSearch;