const accordion = (() => {
    'use strict';
    const init = () => {

        if (!$('.accordion').length) {
            return;
        }

        $('.btn-open-item').on('click keypress', function(e) {
            if (e.which === 13 || e.type === 'click') {
                if ($(this).hasClass('expanded')) {
                    $(this).attr('aria-expanded', 'false').removeClass('expanded');
                    $(this).next('.accordion-body').slideUp(300).attr('aria-hidden', 'true');
                } else {
                    $(this).attr('aria-expanded', 'true').addClass('expanded');
                    $(this).next('.accordion-body').slideDown(300).attr('aria-hidden', 'false');
                }

                if ($('.accordion-item .btn-open-item').length == $('.accordion-item .btn-open-item.expanded').length){
                    $('.btn-expand').attr('aria-expanded', 'true');
                }else {
                    $('.btn-expand').removeAttr('aria-expanded');
                }

                var itemCount = $('.btn-open-item').length;

                if (itemCount === $('btn-open-item.expanded').length) {
                    $('.btn-expand').attr('aria-expanded', 'true').addClass('disabled');
                }

                if ($('.btn-open-item.expanded').length > 0) {
                    $('.btn-collapse').removeClass('disabled').removeAttr('aria-expanded');
                } else {
                    $('.btn-collapse').addClass('disabled');
                }
            }
        });


        $('.btn-expand').on('click keypress', function(e) {
            if (e.which === 13 || e.type === 'click') {
                $(this).attr('aria-expanded', 'true');
                $(this).parent().siblings('.accordion-item').attr('aria-expanded', 'true');
                $(this).parent().siblings('.accordion-item').find('.btn-open-item').addClass('expanded');
                $(this).parent().siblings('.accordion-item').find('.accordion-body').slideDown(300).attr('aria-hidden', 'false');
                $(this).toggleClass('disabled');
                $('.btn-collapse').removeClass('disabled').removeAttr('aria-expanded');
            }
        });


        $('.btn-collapse').attr('aria-expanded', 'false');
        $('.btn-collapse').on('click keypress', function(e) {
            if (e.which === 13 || e.type === 'click') {
                $(this).attr('aria-expanded', 'false');
                $(this).parent().siblings('.accordion-item').attr('aria-expanded', 'false');
                $(this).parent().siblings('.accordion-item').find('.btn-open-item').removeClass('expanded');
                $(this).parent().siblings('.accordion-item').find('.accordion-body').slideUp(300).attr('aria-hidden', 'true');
                $(this).toggleClass('disabled');
                $('.btn-expand').removeClass('disabled').removeAttr('aria-expanded');
            }
        });


        $('.btn-top').on('click keypress', function(e) {
            var headerHeight = $('#header').height();
            if (e.which === 13 || e.type === 'click') {
                $('html, body').animate({
                    scrollTop: $('.accordion-panel').offset().top - headerHeight
                }, 500);
                setTimeout(function(){
                    $('.btn-expand').focus();
                },100);
            }
        });
    };
    return {
        init: init
    };
})();

export default accordion;