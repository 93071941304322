import Swiper from 'swiper';

const slider = (() => {

    'use strict';

    const init = () => {

        let holder = $('.slider-image');

        if (!holder.length) {
            return;
        }

        let mySwiper = new Swiper('.slider-image', {
            a11y: {
                prevSlideMessage: 'Previous slide',
                nextSlideMessage: 'Next slide',
            },
            navigation: {
                nextEl: '.button-slider-next',
                prevEl: '.button-slider-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            init: false
        });

        mySwiper.on('init', function(){
            $('.swiper-slide').css('visibility', 'hidden');
            $('.swiper-slide-active').css('visibility', 'visible');
        });

        mySwiper.init();

        mySwiper.on('slideChange', function () {
          $('.swiper-slide').css('visibility', 'visible');
          setTimeout(function(){
            $('.swiper-slide').css('visibility', 'hidden');
            $('.swiper-slide-active').css('visibility', 'visible');
          },100);
        });

    };

    return {
        init: init
    };
})();

export default slider;