const MOBILE_PER_PAGE = 8;
const DESKTOP_PER_PAGE = 10;
const DESKTOP_PER_PAGE_NEWS = 9;
const PAGINATION_SIDE_PAGES_TO_SHOW = 5;
const PAGINATION_SIDE_PAGES_THRESHOLD = 3;
const MOBILE_WIDTH = 1200;
const TABLET_WIDTH = 1024;

const Paging = {
  template: `
    <div class="pagination">
      <div class="pagination-slide-description">
        Showing {{firstItemIndex}}-{{lastItemIndex}} of {{items.length}}
      </div>
      <div class="pagination-slide-container">
        <a 
          href="#breadcrumb"
          v-if="canShowPrev"
          @click="prev"
          class="pagination-prev go_to"
        ><span class="sr-only">Previous page</span></a>
        <div v-for="page in pages">
          <a 
            href="#breadcrumb"
            v-if="page"
            :class="['pagination-slide',{active: isActive(page)}, 'go_to']"
            @click="select(page)"
          >
            {{page}}
          </a>
          <span
            v-else
            class="pagination-slide-placeholder"
          >
            ...
          </span>
        </div>
        <a 
          href="#breadcrumb"
          v-if="canShowNext"
          @click="next"
          class="pagination-next go_to"
        ><span class="sr-only">Next page</span></a>
      </div>
    </div>
  `,
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      default: DESKTOP_PER_PAGE,
    },
    sidePagesToShow: {
      type: Number,
      default: PAGINATION_SIDE_PAGES_TO_SHOW,
    },
    sidePagesThreshold: {
      type: Number,
      default: PAGINATION_SIDE_PAGES_THRESHOLD,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.items.length / this.perPage);
    },
    pages() {
      let pages = Array.from({length: this.pagesCount},
          (value, index) => index + 1);

      if (this.pagesCount <= this.sidePagesToShow) {
        return pages;
      }

      if (this.page <= this.sidePagesThreshold) {
        pages = [
          ...pages.slice(0, this.sidePagesToShow),
          null,
          pages[this.pagesCount - 1]];
      } else if (this.page > this.pagesCount - this.sidePagesThreshold) {
        pages = [pages[0], null, ...pages.slice(-this.sidePagesToShow)];
      } else {
        pages = [
          pages[0], null,
          ...pages.slice(
            this.page - Math.floor(this.sidePagesToShow / 2),
            this.page + Math.floor(this.sidePagesToShow / 2) - 1
          ),
          null, pages[this.pagesCount - 1],
        ];
      }

      return pages;
    },
    canShowPrev() {
      return this.page > 1;
    },
    canShowNext() {
      return this.page < this.pagesCount;
    },
    firstItemIndex() {
      return (this.page - 1) * this.perPage + 1;
    },
    lastItemIndex() {
      return Math.min(this.page * this.perPage, this.items.length);
    }
  },
  watch: {
    page: {
      handler(page) {
        this.recalculateItems(page);
      },
      immediate: true,
    },
    items() {
      this.recalculateItems(this.page);
    },
    perPage(newPerPage, oldPerPage) {
      const page = Math.ceil((oldPerPage * (this.page - 1) + 1) / newPerPage);

      this.recalculateItems(page);
    },
  },
  methods: {
    prev() {
      if (this.page) {
        this.page = this.page - 1;
      }
    },
    next() {
      if (this.page < this.pagesCount) {
        this.page = this.page + 1;
      }
    },
    select(page) {
      this.page = page;
    },
    isActive(page) {
      return this.page === page;
    },
    recalculateItems(page) {
      this.$emit('input',
        this.items.slice((page - 1) * this.perPage, page * this.perPage));

      this.$emit('change');
    }
  },
};

const Loader = {
  template: `
    <div class="loader">
      <span class="spinner"></span>
    </div>
  `,
};

export default {
  init() {
    if (!document.getElementById('pagination-app')) {
      return;
    }

    const vm = new Vue({
      el: '#pagination-app',
      data() {
        return {
          items: [],
          paginatedItems: [],
          loading: false,
          url: '',
          responsivePerPage: DESKTOP_PER_PAGE,
          responsivePerPageNews: DESKTOP_PER_PAGE_NEWS
        };
      },
      components: {
        paging: Paging,
        loader: Loader,
      },
      beforeMount() {
        this.url = this.$el.dataset.url;
      },
      mounted() {
        this.loadItems();
        this.calcResponsivePerPage();
        this.calcResponsivePerPageNews();
        this.calcResponsivePerPageResizeListener = () => {
          this.calcResponsivePerPage();
          this.calcResponsivePerPageNews();
        };
        window.addEventListener('resize', this.calcResponsivePerPageResizeListener);
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.calcResponsivePerPageResizeListener);
      },
      watch: {
        items: {
          handler(items) {
            this.paginatedItems = items;
          },
        },
      },
      methods: {
        loadItems() {
          this.loading = true;

          axios.get(this.url).then(response => {
            this.items = response.data;
          }).catch(error => {
            console.error(error);
          }).finally(() => (this.loading = false));
        },
        calcResponsivePerPage() {
          if (window.innerWidth < MOBILE_WIDTH) {
            this.responsivePerPage = MOBILE_PER_PAGE;
          } else {
            this.responsivePerPage = DESKTOP_PER_PAGE;
          }
        },
        calcResponsivePerPageNews() {
            if (window.innerWidth < TABLET_WIDTH) {
                this.responsivePerPageNews = MOBILE_PER_PAGE;
            } else {
                this.responsivePerPageNews = DESKTOP_PER_PAGE_NEWS;
            }
        },
        onPaginationChange() {
          this.$nextTick(() => {
            if (window.picturefill) {
              window.picturefill();
            }
          });
        }
      },
    });
  },
};