const mobilePanel = (() => {
    'use strict';
    const init = () => {

        let btnOpen = ".mobile-btn",
            holder = $('#header'),
            searchHolder = $('.search-panel'),
            btnSearch = $('.btn-search'),
            menuHolder = $('.navs-holder'),
            btnEl = $('.mobile-btn'),
            duration = 300;

        if (!$(btnOpen).length) {
            return;
        }

        holder.collapse({
            clickQuery: btnOpen,
            accordion: true,
            open: function() {
                menuHolder.slideDown({
                    start: function () {
                        $(this).css({display: "flex"});
                        $('body').addClass('open-menu-panel');
                    }
                },duration);
                btnEl.attr('aria-expanded', 'true');
            },
            close: function() {
                menuHolder.slideUp(duration);
                $('body').removeClass('open-menu-panel');
                btnEl.attr('aria-expanded', 'false');
            }
        });

        holder.bind("opened", function() {
            searchHolder.slideUp();
            btnSearch.removeClass('is-active');
        });
    };
    return {
        init: init
    };
})();

export default mobilePanel;