//function that take attr href and scroll the pae to this href
const scrollButton = (function($) {
    'use strict';

    function listen() {
        const delay = 1000;
        let elementFocus = $('.header-logo');
        let firstStoryItem = $('.blockquote-holder:first-child .btn');
        const $document = $(document);

        $document.on('click', '.go_to', function(ev) {
            ev.preventDefault();

            const $el = $(this);

            setTimeout(() => {
                let scrollEl = $el.attr('href');

                if ($(scrollEl).length != 0) {
                    $('body,html').animate({ scrollTop: $(scrollEl).offset().top - 30}, delay);
                }
            }, $el.data('go-to-delay') || 0);

            return false;
        })


        $document.on('click', '.back-to-top,.btn-back', function() {
            focusLogo();
        });


        $document.on('click', '.pagination .go_to', function() {
            focusStoryItem();
            focusNewsItem();
        });


        const focusLogo = () => {
            setTimeout(function(){
                elementFocus.focus();
            },1000);
        }

        const focusStoryItem = () => {
            setTimeout(function(){
                $('.blockquote-holder:first-child .btn').focus().attr('tabindex', '-1');
            },1000);
        }

        const focusNewsItem = () => {
            setTimeout(function(){
                $('.new-holder:first-child .new-title').focus().attr('tabindex', '-1');
            },1000);
        }

    }
    return {
        init: listen
    };

}(jQuery));

export default scrollButton;