//function that take attr href and scroll the pae to this href
var fullHeight = (function($) {
    'use strict';

    function init() {
        if (!$(".banner-lg").length) {
            return;
        }

        $(window).on('load resize', function () {
            let headerHeight  = $('#header').height();
            let block = $('.banner-lg');
            let blockHeight = $(window).height() - headerHeight;

            if ($(window).width() >= 700) {
                block.css({
                    height: blockHeight
                });
            }
        });
    }
    return {
        init: init
    };


}(jQuery));

export default fullHeight;