const htmlVideo = (() => {
    'use strict';
    const init = () => {

        let videobg = $(".fullscreen-bg-video");

        if (!$(videobg).length) {
            return;
        }

        const checkVideo = () => {

            Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
                get: function(){
                    return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
                }
            });

            if(!document.querySelector('video').playing){
                $('video').find('.video-sr').css('display','none');
            }else {
                $('.banner-lg').find('.img-sr').css('display','none');
            }
        };

        $(window).on('load', function(){
            checkVideo();
        });

    };
    return {
        init: init
    };
})();

export default htmlVideo;