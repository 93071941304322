const wowAnimation = (() => {
    'use strict';

    function init() {
        var wow = new WOW ({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: true,
            scrollContainer: null
        });

        var wowQuestions = new WOW ({
            boxClass: 'wow-questions',
            animateClass: 'animated',
            offset: 400,
            mobile: true,
            scrollContainer: null
        });

        wow.init();
        wowQuestions.init();
    }
    return {
        init: init
    };
})();

export default wowAnimation;