const storySlider = (() => {

    'use strict';

    const init = () => {

        if (!$('.story-slider').length) {
            return;
        }

        const breakpoint = window.matchMedia( '(min-width:700px)' );

        let mySwiper;

        const breakpointChecker = function() {

            // if larger viewport and multi-row layout needed
            if ( breakpoint.matches === true ) {

                // clean up old instances and inline styles when available
                if ( mySwiper !== undefined ) mySwiper.destroy( true, true );

                // or/and do nothing
                return;

                // else if a small viewport and single column layout needed
            } else if ( breakpoint.matches === false ) {

                // fire small viewport version of swiper
                return enableSwiper();
            }
        };

        const enableSwiper = function() {
            mySwiper = new Swiper('.swiper-container', {
                a11y: {
                    prevSlideMessage: 'Previous slide',
                    nextSlideMessage: 'Next slide',
                },
                navigation: {
                    nextEl: '.button-slider-next',
                    prevEl: '.button-slider-prev',
                },
            });
        };

        breakpoint.addListener(breakpointChecker);
        breakpointChecker();
    };

    return {
        init: init
    };
})();

export default storySlider;