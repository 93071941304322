// You can write a call and import your functions in this file.
//
// This file will be compiled into app.js and will not be minified.
// Feel free with using ES6 here.
import 'babel-polyfill';

import moveElements from './modules/movement-elements';
import mobilePanel from './modules/mobile-panel';
import mobileNav from './modules/mobile-nav';
import openSearch from './modules/open-search';
import collapse from './modules/collapse';
import storiesSlider from './modules/stories-slider';
import storySlider from './modules/story-slider';
import scrollButton from './modules/scroll-button';
import stickyHeader from './modules/sticky-header';
import scrollrEffect from './modules/scrollr';
import filter from './modules/filters';
import pagination from './modules/pagination';
import filterCollapse from './modules/filter-collapse';
import popup from './modules/popup';
import polyfill from './modules/polyfill';
import modulBgImg from './modules/background-img';
import gridItemLength from './modules/grid-item-length';
import openClose from './modules/open-close';
import popupVideo from './modules/popup-video';
import popupFocus from './modules/popup-focus';
import matchHeight from './modules/match-height';
import fullHeight from './modules/full-height';
import dataTable from './modules/data-table';
import datePicker from './modules/datapicker';
import slider from './modules/slider';
import accordion from './modules/accordion';
import autoPlayVideo from './modules/auto-play-video';
import wowAnimation from './modules/wow';
import htmlVideo from './modules/html5-video-bg';
import instagram from './modules/instafeed';
import twitterLoader from './modules/twitter';

( ($) => {
  'use strict';
  instagram.init();

  // When DOM is ready
  $(() => {
    twitterLoader.init();
    autoPlayVideo.init();
    accordion.init();
    slider.init();
    datePicker.init();
    fullHeight.init();
    popupFocus.init();
    popupVideo.init();
    openClose.init();
    polyfill.init();
    moveElements.init();
    openSearch.init();
    storiesSlider.init();
    scrollButton.init();
    stickyHeader.init();
    filter.init();
    pagination.init();
    filterCollapse.init();
    popup.init();
    modulBgImg.init();
    storySlider.init();
    gridItemLength.init();
    matchHeight.init();
    dataTable.init();
    mobilePanel.init();
    mobileNav.init();
    collapse.init();
    wowAnimation.init();
    htmlVideo.init();
    objectFitImages();
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        setTimeout(function(){scrollrEffect.init();}, 1000);
    }
  });
})(jQuery);