const mobileNav = (() => {
    'use strict';
    const init = () => {

        let btnOpen = "span.open-nav",
            holder = $(".nav-site li"),
            duration = 300;

        if (!$(holder).length) {
            return;
        }

        const breakpoint = window.matchMedia('(min-width:1025px)');

        const mobileAccordion = () => {
            $('.open-nav').attr('aria-expanded', 'false');
            $('.open-nav').on('click keypress', function(e) {
                if (e.which === 13 || e.type === 'click') {
                    $(this).toggleClass('open');
                    $(this).toggleAttr('aria-expanded', 'true', 'false');
                    $(this).parent().parent().find('.sub-nav-holder').slideToggle(duration);
                }
            });
            $.fn.toggleAttr = function(attr, attr1, attr2) {
              return this.each(function() {
                var self = $(this);
                if (self.attr(attr) == attr1)
                  self.attr(attr, attr2);
                else
                  self.attr(attr, attr1);
              });
            };
            $('.mobile-btn').on('keydown', (e) => {
                if ($('body').hasClass('open-menu-panel')) {
                    if (e.shiftKey) {
                        if (e.which === 9) {
                            if ($('.nav-site .nav-item:last-child .open-nav').hasClass('open')) {
                                $('.nav-site .nav-item:last-child .sub-nav li:last-child a').focus();
                            }
                            else {
                                $('.nav-site .nav-item:last-child .open-nav').focus();
                            }

                            return false;
                        }
                    }
                }
            });

            $('.nav-site .nav-item:last-child .sub-nav li:last-child a').on('keydown', (e) => {
                if (!e.shiftKey) {
                    if (e.which === 9) {
                        $('.mobile-btn').focus();
                        return false;
                    }
                }
            });
            $('.nav-site .nav-item:last-child .open-nav').on('keydown', (e) => {
                if (!$(e.target).hasClass('open')) {
                    if (!e.shiftKey) {
                        if (e.which === 9) {
                            $('.mobile-btn').focus();
                            return false;
                        }
                    }

                }
            });
        };

        // main navigation init hover drop
        const hoverNav = () => {
            $('ul.nav-site').animDropdown({
                drop: 'div.sub-nav-holder',
                effect: 'slide',
                items: 'li',
                delay: 10
            });
        };

        // information bar init hover drop
        const hoverBar = () => {
            $('ul.information-drop').animDropdown({
                drop: 'div.holder',
                effect: 'slide',
                items: 'li',
                delay: 10
            });
        };

        // tab key open drop
        const tabKey = () =>{
            $('.nav-site, .information-drop').tabNav({
                items: 'li'
            });
        };

        const navHoverDesktop = () => {
            hoverNav();
            hoverBar();
            tabKey();
        };

        const breakpointChecker = function () {
            if (breakpoint.matches === true) {
                navHoverDesktop();
                return;
            } else if (breakpoint.matches === false) {
                mobileAccordion();
            }
        };
        breakpoint.addListener(breakpointChecker);
        breakpointChecker();

        
    };
    return {
        init: init
    };
})();

export default mobileNav;