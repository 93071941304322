const gridItemLength = (() => {
    'use strict';
    const init = () => {

        let holder = $(".grid-img-holder");
        let item = '.grid-item';
        let classActive = 'three-column';

        if (!$(holder).length) {
            return;
        }

        holder.each(function () {
            let length = $(this).find(item).length;
            if(length === 3) {
                $(this).addClass(classActive);
            }
        });
    };
    return {
        init: init
    };
})();

export default gridItemLength;