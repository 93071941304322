const collapse = (() => {
    'use strict';
    const init = () => {

        let btnOpen = '.btn-open',
            holder = $('.information-drop'),
            menuHolder = $('.holder'),
            btnEl = $('.btn-open'),
            duration = 300;

        if (!$(holder).length) {
            return;
        }

        function btnCollapse() {
            holder.collapse({
                clickQuery: btnOpen,
                accordion: false,
                open: function() {
                    $(this.prevObject[0]).find(menuHolder).slideDown(duration);
                    btnEl.attr('aria-expanded', 'true');
                },
                close: function() {
                    $(this.prevObject[0]).find(menuHolder).slideUp(duration);
                    btnEl.attr('aria-expanded', 'false');
                }
            });
        }

        btnCollapse();

    };
    return {
        init: init
    };
})();

export default collapse;